$body-color: #999;
$white: #ffffff;
$lightgray: #fafafa;
$gray: #ecf0f1;
$dark-gray: #a0a0a0;
$dark-blue: #babbbb;
$dark: #333030;
$font: 'Roboto';
$background: rgba(95, 102, 122, 1);
$background-sidebar:  rgba(53, 56, 68, 1);
$background-post:  rgba(53, 56, 68, 1);
$sidebar-link: #abc9f2;
$primary-font-size: 15px;
$secondary-font-size: 13px;
$title-font-size: 21px;