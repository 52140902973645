.blog-tags {
  width: 60%;
  margin: auto;
  background-color: $background;
  padding: 0 20px 20px;
  h1 {
    font-size: 30px;
    text-align: center;
    line-height: 1.3;
    color: $dark-blue;
  }
  h2 {
    color: $dark-blue;
    margin: 10px 0;
  }
}

.tags {
  margin: 0;
  padding: 10px;
  li {
    display: inline-block;
    margin: 5px;
    list-style: none;
    a {
      text-decoration: none;
      color: $dark-blue;
      display: inline-block;
      padding: 5px 10px;
      background-color: $background;
      transition: all .3s;
      &:hover {
        background-color: $background;
      }
    }
  }
}

.tag-list {
  span {
    a {
      text-decoration: none;
      color: $dark-blue;
      &:hover {
        text-decoration: underline;
        opacity: .8;
      }
    }
  }
}
