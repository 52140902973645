.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  height: 100%;
  padding: 20px 10px;
  background-color: $background-sidebar;
}
.sidebar-page-links {
  background-color: rgba(0,0,0,0.1);
  height: 30px;
  border-radius: 7px;

  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin: 0px 0px 10px 0px;
}

.page-link {
  color: inherit !important;
  text-decoration: none;
  margin: 0px;
}
.about {
  margin: 0 0 40px;
  text-align: center;
  .cover-author-image {
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
    border-radius: 100%;
    overflow: hidden;
    background-color: $dark;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: transform 0.35s;
    &:hover {
      transform: scale3d(0.90,0.90,1);
    }
  }
  .author-name {
    font-family: $font, serif;
    margin: 0 0 10px;
    position: relative;
    padding-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: $dark;
    font-weight: 700;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background-color: $body-color;
    }
  }
  p {
    font-size: 16px;
    margin: 0 0 10px;
  }
}
.content-box {
  padding: 20px;
  padding-left: 260px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

.contact {
  .contact-title {
    position: relative;
    color: $body-color;
    font-weight: 400;
    font-size: 12px;
    margin: 0 0 5px;
    text-transform: uppercase;
    text-align: center;
    &::before {
      content: "";
      display: block;
      height: 2px;
      width: calc(50% - 48px);
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      left: 0;
      background-color: $body-color;
    }
    &::after {
      content: "";
      display: block;
      height: 2px;
      width: calc(50% - 48px);
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      right: 0;
      background-color: $body-color;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      a {
        color: $body-color;
        display: block;
        padding: 5px;
        font-size: 18px;
        transition: all 350ms cubic-bezier(0.13, 0.43, 0.54, 1.82);
        &:hover {
          color: $dark;
          transform: scale(1.2);
        }
      }
    }
  }
}

footer {
  .copyright {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }
}

.post {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 200px;
  border-radius: 10px;
  overflow: hidden;
  transition: all .3s ease;
  box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);
  &:hover {
    transform: translate(0px, -2px);
    box-shadow: 0 15px 45px -10px rgba(10, 16, 34, .2);
  }

  .post-thumbnail {
    width: 30%;
    max-width: 100%;
    min-height: 100px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .post-content {
    padding: 1rem;
    width: 70%;
    .post-date,
    .post-words {
      font-size: 12px;
    }
    .post-title {
      margin: 0 0 10px;
      font-size: 30px;
      font-weight: 400;
      line-height: 70%;
      a {
        font-family: $font, serif;
        text-decoration: none;
        color: $dark-blue;
      }
    }
    p {
      margin-top: 0;
    }
  }
}

a.older-posts, a.newer-posts {
  font-size: 18px;
  display: inline-block;
  color: $body-color;
  transition: transform .2s;
}

a.older-posts:hover {
  transform: translateX(5px);
}

a.newer-posts:hover {
  transform: translateX(-5px);
}