.wrap-content {
  padding: 20px;
}

.header-page {
  text-align: center;
  margin: 10px 0;
  .page-title {
    font-weight: 400;
    margin: 0 0 10px;
    line-height: 1.3;
  }
  .page-date {
    color: $dark-gray;
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.page-content {
  max-width: 500px;

  a {
    color: $sidebar-link !important;
    text-decoration: none;
  }
}

.page-cover-image {
  border-radius: 20px 20px 0px 0px;
  position: relative;
  padding: 0;
  margin: 0;
  max-height: 500px;
  background-color: $lightgray;
  overflow: hidden;
  figure {
    position: relative;
    margin: 0;
  }
  figcaption {
    position: absolute;
    top: 20px;
    right: 20px;
    padding-left: 20px;
    color: rgba(255, 255, 255, 0.7);
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 10px;
  }
  .page-image {
    width: 100%;
    height: 100%;
  }
}

.page-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 30px;
  margin: 30px 0  ;
  .page-share {
    margin-bottom: 10px;
    text-align: left;
    a {
      display: inline-block;
      text-decoration: none;
      background-color: $lightgray;
      border: 1px solid $gray;
      padding: 5px 10px;
      margin-left: 5px;
      color: $dark-blue;
      font-size: 12px;
      transition: background-color .3s;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        background-color: $gray;
      }
    }
  }
  .page-tag {
    a {
      text-decoration: none;
      font-size: 10px;
      padding: 5px 10px;
      margin-left: 5px;
      color: $dark-blue;
      text-transform: uppercase;
      border: 1px solid $dark-blue;
      line-height: 30px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.page-number {
  color: $dark-blue;
}